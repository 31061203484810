import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Flex, Box } from "reflexbox";
import { BlogTitle, PostDate, PostBox } from "../styled/blog";
import { formatDate } from "../utils/date";
import SEO from "../components/seo";
import Tags from "../components/blog/tags";
import { H1 } from "../components/typography/styled/typography";
import { LangLink } from "../components/lang-link";
import { Container } from "../components/layout/container";
import { Ornament } from "../components/layout/ornament";
import { Lang } from "../components/tr";
import BlogMenu from "../components/blog/blogMenu";

const TagPage = ({ data, pageContext: { tag, lang = Lang.cs }, location }) => {
  const postList = data.allMarkdownRemark;
  const categories = [].concat(...data.categories.edges.map((edge) => edge.node.frontmatter.tags));
  const uniqueCategories = [...new Set(categories)];

  return (
    <Layout lang={lang} location={location}>
      <SEO lang={lang} title="Blog" />
      <Container>
        <Ornament />
        <Flex flexWrap={"wrap"} justifyContent={"center"}>
          <Box width={[1, 1, 1, 2 / 3]} py={4} px={[3, 3, 3, 0, 0]}>
            <H1>
              #{tag}{" "}
              <small>
                <LangLink to={"/blog"}>&#10006;</LangLink>
              </small>
            </H1>
            {postList.edges.map(({ node }, i) => (
              <PostBox key={i}>
                <BlogTitle>
                  <LangLink to={`/blog${node.fields.slug}`}>
                    {node.frontmatter.title}
                  </LangLink>
                </BlogTitle>

                <PostDate>{formatDate(node.frontmatter.date)}</PostDate>

                <p>{node.excerpt}</p>

                <Tags tags={node.frontmatter.tags} />
              </PostBox>
            ))}
          </Box>
          <Box width={[1,1,1, 1/3]} py={4}>
            <BlogMenu items={uniqueCategories} />
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default TagPage;

export const listQueryByTag = graphql`
  query ListQueryByTag($tag: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 410)
          frontmatter {
            date
            title
            tags
          }
        }
      }
    }

    categories: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { glob: "**/blog/*.md" } }
    ) {
      edges {
        node {
          frontmatter {
            tags
          }
        }
      }
    }
  }
`;
