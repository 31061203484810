import { MenuBox, TopicTitle, MenuTag, About } from "../../styled/blog";
import React from "react";
import {Link} from "gatsby";
import {Translate, TranslateSwitch} from "../tr";
import {H3} from "../typography/styled/typography";

const BlogMenu = ({ items }) => {
  return (
    <MenuBox>
      <TopicTitle><Translate text={'Topics'} /></TopicTitle>
      {items.map((item) => (
        <MenuTag>
          <Link to={`/blog/tags/${item}`}>{item}</Link>
        </MenuTag>
      ))}

      <About>
        <H3 id={'#my-story'}>
          <Translate text={"My story"} />
        </H3>
        <TranslateSwitch
          en={
            <>
              <p>As a little girl I didn’t have careless childhood. A lot of situations was quite complicated and we didn’t  belong among wealthy families. But even though I realized I could feel better and then it started a misson for happy life.</p>
              <p>I read once if I have something pretty in front of my eyes even it should be daisy in the glass so it helps me to feel well. Since then I had been thinking about it.</p>

              <p>At the moment I changed my view and I started to think what to have around me and how can I do it. Later there were posters of my favorite music bands and then I was staring on them for so long until I desired to draw them, to express what I can see in their faces. And so I had started to turn into drawings my deep feelings  which nobody has no clue about . And from black and white they became colored. Up to once I discovered oil paints which I fell in love with completely. At that moment I understood there is no way back and what was only hobby became my life purpose. To catch beauty or feelings around, to my picture. I understood this is important part of me, my essence and maybe one of the reasons why I’m here on the Earth.</p>

              <p>Every brush stroke compensated hours of therapies I would need to be what I’m without a shame. From the angry and scattered person hard skin peeled off and I’ve started to integrate my sensitive part until I realized I belong to hypersensitive people. This reality helped to perceive as an advantage and I can use these abilities in artistic creations.</p>
            </>
          }
          cs={
            <>
              <p>Jako malá holka jsem neměla úplně ideální dětství. Mnoho věcí bylo komplikovaných, nepatřili jsme mezi movité rodiny. Ale už jako malá jsem si uvědomovala, že bych se přeci jen mohla cítit lépe a už tehdy začala má mise za šťastným životem.</p>
              <p>Jednou jsem si někde přečetla, že aby se člověk cítil dobře, stačí pár jednoduchých věcí, jako třeba mít na očích pořád něco krásného, i kdyby to měla být sedmikráska ve sklenici. </p>

              <p>A v tu chvíli se mi změnil pohled a já se začala obklopovat hezkými jednoduchými věcmi a obrázky. Později to byly plakáty mých oblíbených hudebních skupin, na které jsem koukala tak dlouho, dokud jsem nezatoužila je umět nakreslit. A tak jsem začala proměňovat své hluboké pocity, o kterých nikdo nevěděl, do kreseb.</p>
              <p>A z černobarevných se stávaly barevné. Až jsem jednou objevila olejové barvy, kterým jsem naprosto propadla. A v tu chvíli jsem pochopila, že není cesty zpět a co dříve bylo jen koníčkem, stalo se životním posláním, zachytit tu krásu kolem do svého obrazu. Pochopila jsem, že obrazové vyjádření je moje důležitá část, součást mé podstaty, možná jeden z důvodů, proč na téhle Zemi jsem.</p>

              <p>Každý tah štětcem vynahradil hodiny terapie a z roztěkaného a naštvaného člověka se odlupovala ztvrdlá slupka, začala jsem objevovat svou jemnou stránku až jsem si jednoho dne uvědomila, že patřím mezi hypersenzitivní lidi. Tato skutečnost mi pomohla svou jemnost začít vnímat jako výhodu, nikoliv jako prokletí, a že mohu tyto vlastnosti využívat právě při umělecké tvorbě.</p>
            </>
          }
        />
      </About>
    </MenuBox>
  );
}

export default BlogMenu;
